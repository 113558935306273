<template>
  <div class="kr-talent-list-page">
    <!-- [筛选项] -->
    <kr-card :padding="[0, 0, 12, 0]" @rect:update="onTopCardRectUpdate">
      <a-form layout="inline" :form="queryParams">
        <div class="filter-condition-form">
          <a-form-item label="手机号码：">
            <a-input
              v-model.trim="queryParams.mobile"
              allowClear
              placeholder="请输入手机号码"
              style="width: 190px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item>
            <div class="btn-lines">
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button style="margin-right: 12px" @click="() => {
                this.queryParams.mobile = ''
                this.reload()
              }"
              >重置</a-button
              >
            </div>
          </a-form-item>
        </div>
      </a-form>
    </kr-card>
    <!-- [列表] -->
    <a-table
      class="tablecl"
      :class="list.length * 36 < tableScrollY ? 'less-table' : ''"
      :scroll="{ x: 300, y: tableScrollY }"
      :rowKey="record => record.mobile"
      :columns="goodsColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="locale"
      @change="paginationHandler"
    >
    </a-table>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import {
  timeFormat,
} from '@/filters/shop';
import { Empty } from 'ant-design-vue';
import { getTalentList } from "@/service/user";

export default defineComponent({
  name: 'KrTalentList',
  data() {
    return {
      locale: {
        emptyText: <a-empty description="暂无数据" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      },
    };
  },
  methods: {
    paginationHandler(params) {
      if (params.pageSize !== this.queryParams.page_size) {
        this.queryParams.page = 1;
        this.pagination.current = 1;
        this.queryParams.page_size = params.pageSize;
      } else {
        this.pagination.current = params.current;
        this.queryParams.page = params.current;
      }
      this.reload(false);
    },
  },
  setup(_) {
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 280);
    const onTopCardRectUpdate = rect => {
      tableScrollY.value = windowHeight - 220 - rect.height;
    };
    // 商品列表
    const goodsColumns = [
      {
        title: '手机号码',
        width: 160,
        align: 'left',
        dataIndex: 'mobile',
        customRender: text => {
          return  text || '--';
        },
      },
      {
        title: '注册时间',
        dataIndex: 'create_time',
        width: 150,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return  text ? timeFormat(text,'YYYY-MM-DD HH:mm') : '--';
        },
      },
      {
        title: '最后访问时间',
        dataIndex: 'last_login_time',
        width: 150,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return  text ? timeFormat(text,'YYYY-MM-DD HH:mm') : '--';
        },
      },
    ];

    const list = ref([]);
    const pagination = ref({
      size: 'small',
      current: 1,
      pageSize: 20,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      showTotal: total =>
        `共 ${total} 条记录 第 ${pagination.value.current}/${Math.ceil(
          total / pagination.value.pageSize,
        )} 页`,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: (current, pageSize) => (pagination.value.pageSize = pageSize),
    });
    const queryParams = ref({
      page: 1,
      page_size: 20,
      mobile: '',
    });
    const reset = () => {
      queryParams.value = {
        page: 1,
        page_size: queryParams.value.page_size,
        mobile: '',
      };
      reload();
    };

    const listLoading = ref(false);
    const getGoodsList = async () => {
      listLoading.value = true;
      const { err, res } = await getTalentList({
        page: queryParams.value.page,
        page_size: queryParams.value.page_size,
        mobile: queryParams.value.mobile,
      });
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          queryParams.value.id = '';
          const one_pagination = { ...pagination.value };
          list.value = res.data.results;
          one_pagination.total = res.data.count;
          pagination.value = one_pagination;
        }
      }
    };

    const reload = async (clean = true, formData = null) => {
      if (clean) {
        pagination.value.current = 1;
        queryParams.value.page = 1;
      }
      if (formData) {
        queryParams.value = formData;
      }
      await getGoodsList();
    };

    return {
      reset,
      onTopCardRectUpdate,
      pagination,
      listLoading,
      goodsColumns,
      tableScrollY,
      list,
      queryParams,
      reload,
    };
  },
  created() {
    this.reload();
  },
});
</script>

<style lang="less">
.kr-talent-list-page {
  display: flex;
  flex-direction: column;
  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item-control,
  .ant-input,
  .ant-btn {
    line-height: 26px;
    display: inline-block;
  }
  .btn-lines {
    .ant-btn {
      margin-right: 12px;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
    margin-right: 12px;
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding: 0 12px;
    }
  }
  .mr-10 {
    margin-right: 10px;
  }
  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td {
    height: 36px !important
  }
  .ant-table-tbody > tr > td {
    padding: 7px 16px;
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  /deep/.ant-table-tbody > tr {
    height: 36px !important;
  }
  /deep/.ant-table-tbody > tr > td {
    height: 36px !important;
    padding: 7px 16px;
  }
  .ant-table-pagination.ant-pagination .ant-pagination-options-quick-jumper,
  .ant-pagination.mini .ant-pagination-total-text,
  .ant-pagination.mini .ant-pagination-simple-pager {
    font-size: 12px;
  }
  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item .ant-form-item-control-wrapper,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control ant-input-affix-wrapper,
  .ant-input,
  .ant-btn,
  .ant-select,
  .ant-select-selection__rendered,
  .ant-select-selection--single {
    font-size: 12px;
    height: 28px;
    line-height: 27px;
  }
  .less-table {
    &.ant-table-wrapper {
      flex: 1;
      display: flex;
      .ant-spin-nested-loading {
        position: inherit;
        height: 100%;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        .ant-spin-container {
          position: inherit;
          -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;
          display: flex;
          flex-direction: column;
          width: 100%;
          .ant-table-pagination.ant-pagination {
            text-align: right;
          }
          .ant-table-fixed-header .ant-table-body-inner {
            overflow-y: auto !important;
          }
          .ant-table-body {
            min-height: calc(100vh - 280px);
          }
          .ant-table-placeholder {
            margin-top: calc(200px - 60vh);
            margin-bottom: calc(40vh - 46px);
            border: none;
          }
        }
      }
    }
  }
}
</style>
